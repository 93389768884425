.about-container {
  text-align: center;
  padding: 50px 20px;
  background-color: white;
  overflow: hidden;
}

.about-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description-wrapper {
  position: relative;
  max-width: 600px; /* Matches DesignAutomation content-box */
  margin: 0 auto 40px; /* Adjusted margin for spacing */
  padding: 20px; /* Padding for content */
  background: #fff;
  border-radius: 30px; /* Matches DesignAutomation content-box */
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

.about-description {
  font-size: 18px;
  line-height: 1.6;
  margin: 0; /* Remove default margin */
  position: relative;
  z-index: 2; /* Text above gradient and mask */
}

.owners {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.owner {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.photo-frame {
  position: relative;
  width: 170px;
  height: 170px;
  margin: 0 auto 10px;
}

.owner-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}

.gradient-ring,
.cover-layer {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.gradient-ring {
  z-index: 0;
  background: linear-gradient(45deg, #FF4500, #FFFF00, #FF8C00, #FF4500);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.photo-frame .gradient-ring { /* Specific to images */
  border-radius: 50%;
  padding: 10px;
  inset: 8px;
}

.description-wrapper .gradient-ring { /* Specific to description */
  border-radius: 30px; /* Matches DesignAutomation */
  inset: 1px;
  padding: 2px; /* Matches DesignAutomation */
}

.cover-layer {
  z-index: 1;
}

.photo-frame .cover-layer { /* Specific to images */
  border-radius: 50%;
  border: 15px solid rgb(255, 255, 255);
  mask-image: none;
  -webkit-mask-image: none;
}

.description-wrapper .cover-layer { /* Specific to description */
  border-radius: 30px; /* Matches DesignAutomation */
  border: 10px solid rgb(255, 255, 255); /* Matches DesignAutomation */
  mask-image: none;
  -webkit-mask-image: none;
}

.owner-name {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: -3px;
  display: inline-block;
}

.owner-info {
  font-size: 14px;
}

@media (max-width: 768px) {
  .about-title {
    font-size: 20px;
  }

  .description-wrapper {
    margin: 0 15px 30px; /* Adjusted for smaller screens */
    padding: 15px;
  }

  .about-description {
    font-size: 16px;
  }

  .photo-frame {
    width: 120px;
    height: 120px;
  }

  .owner-image {
    width: 100px;
    height: 100px;
    top: 10px;
    left: 10px;
  }

  .photo-frame .cover-layer {
    border: 10px solid rgb(255, 255, 255);
  }

  .owner-info {
    font-size: 12px;
  }
}