.three-d-printing {
  text-align: center;
  padding: 50px 20px;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden; /* Estetään ylivuoto */
  opacity: 0; /* Alussa näkymätön */
  animation: fadeIn 0.5s forwards; /* Fade-in-animaatio */
  margin-top: -2em;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-out {
  animation: fadeOut 0.5s forwards; /* Fade-out-animaatio */
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.navigation-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.left-arrow, .right-arrow {
  font-size: 24px;
  color: var(--main-color); /* Pääväri */
  text-decoration: none;
  position: relative;
  margin: 0 20px; /* Lisätään enemmän tilaa nuolen ja tekstin väliin */
  transition: transform 0.3s; /* Sulava suurennus */
  cursor: pointer; /* Muutetaan osoitin osoittamaan, että ne ovat interaktiivisia */
}

.left-arrow:hover, .right-arrow:hover {
  transform: scale(1.2); /* Suurennusefekti */
}

.hover-text {
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none; /* Estetään hover-tekstiä vaikuttamasta osoittimen tapahtumiin */
}

.left-hover {
  right: 100%;
  transform: translateX(-300%); /* Asetetaan alkuperäinen sijainti ruudun ulkopuolelta pidemmälle */
}

.right-hover {
  left: 100%;
  transform: translateX(300%); /* Asetetaan alkuperäinen sijainti ruudun ulkopuolelta pidemmälle */
}

.left-arrow:hover .left-hover {
  animation: appear-left 0.2s forwards;
}

.left-arrow:not(:hover) .left-hover {
  animation: disappear-left 0.2s forwards;
}

.right-arrow:hover .right-hover {
  animation: appear-right 0.2s forwards;
}

.right-arrow:not(:hover) .right-hover {
  animation: disappear-right 0.2s forwards;
}

@keyframes appear-left {
  0% { opacity: 0; transform: translateX(-500%); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes disappear-left {
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(-500%); }
}

@keyframes appear-right {
  0% { opacity: 0; transform: translateX(500%); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes disappear-right {
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(500%); }
}

.three-d-printing h1.main-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--main-color); /* Pääväri */
}

.three-d-printing p {
  font-size: 18px;
  margin-bottom: 20px;
  max-width: 600px; /* Maksimileveys, jotta tekstirivi on noin 70 merkkiä */
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6; /* Riviväli paremman luettavuuden takaamiseksi */
}

.section-divider {
  width: 40%;
  margin: 20px auto;
  border: 0;
  height: 1px;
  background: #ccc; /* Harmaa väri */
}

.image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  position: relative; /* Lisätään position relative, jotta gradientti voi olla kuvan päällä */
}

.three-d-image {
  max-width: 300px;
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer; /* Muutetaan osoitin osoittamaan, että kuva on interaktiivinen */
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px; /* Häivytyksen korkeus */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Musta tausta 80% läpinäkyvyydellä */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Z-index korkeampi kuin muilla elementeillä */
  animation: fadeIn 0.5s forwards; /* Fade-in-animaatio */
}

.expanded-image {
  width: auto; /* Kuva vie 80% leveydestä */
  height: 80%; /* Säilytetään mittasuhteet */
  border-radius: 20px;
}

.fade-out {
  animation: fadeOut 0.5s forwards; /* Fade-out-animaatio */
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

/* Mobiiliyhteensopivuus */
@media (max-width: 768px) {
  .three-d-printing h1.main-title {
    font-size: calc(16px + 2vw); /* Skaalataan otsikon fonttikoko näytön leveyden mukaan */
  }

  .left-arrow, .right-arrow {
    font-size: calc(12px + 1.5vw); /* Skaalataan nuolien fonttikoko näytön leveyden mukaan */
    margin: 0 5px; /* Siirretään nuolia lähemmäs tekstiä */
  }

  .hover-text {
    display: none; /* Piilotetaan hover-tekstit mobiilissa */
  }

  .expanded-image {
    width: 100%; /* Kuva vie 100% leveydestä mobiilissa */
    height: auto; /* Säilytetään mittasuhteet */
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
