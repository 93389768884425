/* src/components/Map.css */
.map-container {
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgb(255, 69, 0) 2px 0px 0px, rgb(255, 69, 0) 1.75517px 0.958851px 0px, rgb(255, 69, 0) 1.0806px 1.68294px 0px, rgb(255, 69, 0) 0.141474px 1.99499px 0px, rgb(255, 69, 0) -0.832294px 1.81859px 0px, rgb(255, 69, 0) -1.60229px 1.19694px 0px, rgb(255, 69, 0) -1.97998px 0.28224px 0px, rgb(255, 69, 0) -1.87291px -0.701566px 0px, rgb(255, 69, 0) -1.30729px -1.5136px 0px, rgb(255, 69, 0) -0.421592px -1.95506px 0px, rgb(255, 69, 0) 0.567324px -1.91785px 0px, rgb(255, 69, 0) 1.41734px -1.41108px 0px, rgb(255, 69, 0) 1.92034px -0.558831px 0px;
    text-align: center;
    width: 50%; /* Asetetaan kartan leveys 50% */
    height: 59vh;
    margin-left: auto; /* Keskitetään kartta */
    margin-right: auto; /* Keskitetään kartta */
  }
  
  .map-frame {
    transition: filter 0.5s ease; /* Smooth transition for grayscale */
    width: 100%; /* Tehdään kartasta kontainerin levyinen */
    height: 60vh;
  }
  
  .map-frame.grayscale {
    filter: grayscale(50%); /* Apply grayscale filter */
  }
  
  .toggle-button {
    width: 20px;
    height: 20px;
    background-color: var(--main-color);
    border-radius: 50%;
    margin: 10px auto;
    cursor: pointer;
    transition: background-color 0.5s ease, transform 0.3s ease;
  }
  
  .toggle-button:hover {
    transform: scale(1.1);
  }
  
  .toggle-button.active {
    background-color: grey;
  }
  
  .map-section {
    padding: 20px;
    text-align: center;
  }
  
  .map-title {
    color: var(--main-color); /* Use the main color */
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
   .map-container {
    width: 100%; /* Asetetaan kartan leveys 50% */
    height: 59vh;
    margin-left: auto; /* Keskitetään kartta */
    margin-right: auto; /* Keskitetään kartta */
  }
  
  .map-frame {
    width: 100%; /* Tehdään kartasta kontainerin levyinen */
    height: 60vh;
  } 
}