.header {
  padding: 20px 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
  font-family: 'Montserrat', sans-serif;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex: 1;
  z-index: 1000;
}

.nav-links li {
  margin: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.nav-links li a,
.nav-links li button {
  display: inline-block;
  color: black;
  text-decoration: none;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.nav-links li a:hover,
.nav-links li button:hover {
  color: #FF4500;
  transform: scale(1.1);
}

.language-button-container {
  position: relative;
}

.language-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  transition: transform 0.3s ease;
  margin-top: 5px;
  position: relative;
}

.language-button svg {
  width: 24px;
  height: 24px;
}

.language-button:hover {
  transform: scale(1.1);
}

.language-dropdown {
  position: absolute;
  top: 80%;
  left: 1px;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
}

.language-dropdown.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.language-button-container:hover .language-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.language-dropdown li {
  padding: 5px 0px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.language-dropdown li svg {
  width: 24px;
  height: 24px; 
  margin-right: 0px;
}

.language-dropdown li:hover {
  transform: scale(1.1);
  background-color: transparent; /* Poistetaan taustaväri */
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.4s;
  transform: translateX(-50%) rotate(90deg);
  transform-origin: center;
}

.hamburger-menu.open {
  transform: translateX(-50%) rotate(0deg);
}

.hamburger-menu svg {
  width: 25px;
  height: 25px;
  fill: var(--main-color);
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
    position: fixed;
    top: 10px;
    left: 50%;
    z-index: 1001;
  }

  .nav-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0) 95%);
    z-index: 1000;
  }

  .nav-background.open {
    display: block;
    animation: slideDownBg 0.5s forwards;
  }

  .nav-background.closed {
    display: block;
    animation: slideUpBg 0.5s forwards;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 1001;
  }

  .nav-links.open {
    display: flex;
    animation: slideDown 0.5s forwards;
  }

  .nav-links.closed {
    display: flex;
    animation: slideUp 0.5s forwards;
  }

  .nav-links.hidden {
    display: none;
  }

  .nav-links li {
    margin: 5px 0;
  }

  .nav-links li a,
  .nav-links li button {
    color: var(--main-color);
  }

  .language-button {
    margin-right: 5px;
  }

  .language-button-container {
    order: -1;
    margin-bottom: 10px;
  }

  .language-dropdown {
    padding: 5px 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: white;
    left: -1px;
  }
  
  .language-dropdown.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
  
  @keyframes slideDown {
    0% { transform: translateY(-100%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }

  @keyframes slideUp {
    0% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(-100%); opacity: 0; }
  }

  @keyframes slideDownBg {
    0% { transform: translateY(-100%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }

  @keyframes slideUpBg {
    0% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(-100%); opacity: 0; }
  }
}
