.design-automation {
  padding: 50px 20px;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  margin-top: -2em;
  position: relative;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.navigation-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.left-arrow, .right-arrow {
  font-size: 24px;
  color: #FF4500;
  text-decoration: none;
  position: relative;
  margin: 0 40px;
  transition: transform 0.3s;
  cursor: pointer;
}

.left-arrow:hover, .right-arrow:hover {
  transform: scale(1.2);
}

.hover-text {
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
}

.left-hover {
  right: 100%;
  transform: translateX(-300%);
}

.right-hover {
  left: 100%;
  transform: translateX(300%);
}

.left-arrow:hover .left-hover {
  animation: appear-left 0.2s forwards;
}

.left-arrow:not(:hover) .left-hover {
  animation: disappear-left 0.2s forwards;
}

.right-arrow:hover .right-hover {
  animation: appear-right 0.2s forwards;
}

.right-arrow:not(:hover) .right-hover {
  animation: disappear-right 0.2s forwards;
}

@keyframes appear-left {
  0% { opacity: 0; transform: translateX(-500%); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes disappear-left {
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(-500%); }
}

@keyframes appear-right {
  0% { opacity: 0; transform: translateX(500%); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes disappear-right {
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(-500%); }
}

.design-automation h1.main-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #FF4500;
  text-align: center;
}

.design-automation h2 {
  font-size: 24px;
  font-weight: bold;
  color: #FF4500;
  margin: 0 0 15px;
  text-align: center;
}

.content-box {
  position: relative;
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
  background: #fff;
  border-radius: 30px;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

.content-box.intro {
  margin: 30px auto;
}

.content-box.solution {
  margin-right: 20%;
}

.content-box.configurator {
  margin-left: 20%;
}

.content-box.custom {
  margin-right: 20%;
}

.content-box.closing {
  margin: 20px auto;
}

.video-container-automation {
  position: relative;
  max-width: 500px;
  max-height: 290px;
  margin: 20px auto;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

.orange-border,
.white-mask {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  border-radius: inherit;
}

.orange-border {
  z-index: 0;
  background: linear-gradient(45deg, #FF4500, #FFFF00, #FF8C00, #FF4500);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  inset: 1px;
  padding: 2px;
}

.white-mask {
  z-index: 1;
  border: 10px solid rgb(255, 255, 255);
  mask-image: none;
  -webkit-mask-image: none;
}

.content-box p, .content-box ul {
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  color: #333;
  position: relative;
  z-index: 2;
  text-align: center;
}

.benefits-list {
  list-style-type: disc;
  margin: 10px auto 0;
  padding: 0;
  display: inline-block;
  text-align: left;
}

.highlight {
  font-weight: bold;
  color: #FF4500;
}

.section-divider {
  width: 40%;
  margin: 20px auto;
  border: 0;
  height: 1px;
  background: #ccc;
}

.video-wrapper-automation {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.design-video {
  max-width: 480px;
  width: 100%;
  height: auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s forwards;
}

.expanded-video {
  width: 80%;
  height: auto;
  border-radius: 20px;
}

.demo-button {
  display: block;
  margin: 20px auto;
  background-color: #FF4500;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.3s;
}

.demo-button:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .design-automation h1.main-title {
    font-size: calc(16px + 2vw);
  }

  .design-automation h2 {
    font-size: calc(14px + 1.5vw);
  }

  .content-box {
    padding: 15px;
    margin: 15px auto !important;
  }

  .video-container-automation {
    padding: 15px;
    margin: 15px auto !important;
  }

  .content-box p, .content-box ul {
    font-size: 16px;
  }

  .left-arrow, .right-arrow {
    font-size: calc(12px + 1.5vw);
    margin: 0 5px;
  }

  .hover-text {
    display: none;
  }

  .expanded-video {
    width: 100%;
    height: auto;
  }

  .demo-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}