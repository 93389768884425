.bottom {
  background-color: white;
  padding: 20px 0;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.bottom-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.bottom-content p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.bottom-content a {
  color: #FF4500; /* Pääväri */
  text-decoration: none;
  transition: color 0.3s;
}

.bottom-content a:hover {
  color: #333;
}

