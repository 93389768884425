.landing-page {
  background-color: white;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Koko näkymäkorkeus */
  position: relative; /* Lisää position relative varmistamaan, että z-index toimii oikein */
  z-index: 1; /* Asetetaan z-index, jotta landing-page-elementti on footerin yläpuolella */
  overflow: hidden; /* Piilotetaan ylivuoto */
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 20vh;
}

.video-container video {
  width: 960px;
  height: 540px;
  transition: opacity 1.5s ease; /* Sulava häivytys */
}

.video-container video.hidden {
  opacity: 0; /* Häivytys pois näkyvistä */
}

.arrow {
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%) scale(1, 1); /* Aluksi normaalikoko */
  font-size: 3rem;
  cursor: pointer;
  color: #FF4500;
  animation: moveDown 3s infinite;
  transition: transform 0.3s ease-in-out; /* Sulava siirtymä hoverille */
}


@keyframes moveDown {
  0%, 60%, 100% {
    transform: translateX(-50%) scale(1, 1); /* Y-akselilla 3x normaalitilassa */
  }
  80% {
    transform: translateX(-50%) translateY(4px) scale(1, 1.2); /* Liikkuu Y-akselilla ja skaalaus säilyy */
  }
}



@media (max-width: 768px) {

  .landing-page {
    padding: 0;
    height: 100vh; /* Koko näkymäkorkeus mobiilissa */
  }

  .video-container {
    margin-top: 0;
    width: 100%;
    height: 100%;
    margin-top: -10vh;
  }

  .video-container video {
    width: 100%;
    height: auto; /* Skaalaa korkeus automaattisesti säilyttäen mittasuhteet */
  }

  .arrow {
    display: none;
  }
}
