.configurator-container {
  display: flex;
  justify-content: space-between; /* Asetetaan elementit vierekkäin */
  background-color: #ffffff; /* Valkoinen taustaväri */
  padding: 20px;
  height: 90vh; /* Kiinteä korkeus */
  overflow: hidden; /* Estetään rullaus */
  margin-top: 15vh;;
}

.canvas-container, .drawing-container {
  width: 60%; /* 3D-näkymä ja kuva vievät 60% leveydestä */
  display: flex;
  justify-content: center; /* Keskitetään sisältö */
  align-items: center; /* Keskitetään pystysuunnassa */
  height: 100vh; /* Koko ruudun korkeus */
  box-sizing: border-box; /* Sisällytä padding ja reunaviivat elementin kokoon */
}


.controls-container {
  width: 40%; /* Käyttäjävalinnat vievät 40% leveydestä */
  display: flex;
  flex-direction: column; /* Asetetaan kontrollit päällekkäin */
  align-items: flex-start; /* Tasataan vasemmalle */
  gap: 20px;
  margin-top: 25vh; /* Alennetaan noin puoleenväliin 3D-näkymää korkeussuunnassa */
  height: calc(100vh - 40vh); /* Koko ruudun korkeus miinus marginaali */
  overflow-y: auto; /* Salli pystysuuntainen vieritys */
  padding: 20px; /* Lisätään padding */
  box-sizing: border-box; /* Sisällytä padding ja reunaviivat elementin kokoon */
}


.dimension-controls,
.material-controls,
.fall-protection-controls,
.top-rungs-removed-controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start; /* Tasataan vasemmalle */
  margin-top: 5px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

label {
  font-size: 20px; /* Suurennetaan fonttikokoa */
  color: #333;
}

input[type="number"],
select {
  font-size: 18px; /* Suurennetaan fonttikokoa */
  width: 100px;
  padding: 10px; /* Suurennetaan paddingia */
  cursor: pointer;
  border-radius: 10px; /* Pyöristetään reunat */
  border: 1px solid #ccc; /* Lisätään reunaviiva */
}

button.ok-button,
button.center-button,
button.view-toggle-button {
  font-size: 20px; /* Suurennetaan fonttikokoa */
  padding: 10px 20px; /* Suurempi padding kasvattaa kokoa */
  cursor: pointer;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 10px; /* Pyöristetään reunat */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Muutettu transition */
}

button.ok-button:hover,
button.center-button:hover,
button.view-toggle-button:hover {
  background-color: #FF4500;
  transform: scale(1.1);
}

canvas {
  border-radius: 10px;
  height: 100%; /* Kiinteä korkeus */
  width: 100%; /* 3D-näkymä vie 100% leveydestä containerissa */
  margin-bottom: 20px; /* Lisätään marginaali alapuolelle */
}

.large-checkbox {
  width: 20px; /* Leveys */
  height: 20px; /* Korkeus */
  cursor: pointer;
}

.svg-container img.large-image {
  margin-top: 5em;
  height: 70%; /* Korkeus 90% */
  width: auto; /* Leveys automaattinen */
  transform: scale(0.9);
}

.buttons-container {
  display: flex;
  justify-content: flex-start; /* Muutettu justify-content */
  width: 100%;
  gap: 10px; /* Lisätty väli painikkeiden väliin */
}


@media (max-width: 768px) {
  .configurator-container {
    flex-direction: column; /* Asetetaan elementit päällekkäin mobiilissa */
    align-items: center;
    height: auto; /* Automaattinen korkeus mobiilissa */
  }

  .canvas-container, .drawing-container {
    width: 100%; /* 3D-näkymä ja kuva vievät 100% leveydestä mobiilissa */
    height: auto; /* Automaattinen korkeus mobiilissa */
  }

  .controls-container {
    width: 100%; /* Käyttäjävalinnat vievät 100% leveydestä mobiilissa */
    margin-top: 20px; /* Poistetaan ylimääräinen marginaali mobiilissa */
    align-items: center; /* Keskitetään mobiilissa */
    height: auto; /* Automaattinen korkeus mobiilissa */
  }

  .dimension-controls,
  .material-controls,
  .fall-protection-controls,
  .top-rungs-removed-controls,
  .buttons-container {
    align-items: center; /* Keskitetään mobiilissa */
  }

  canvas {
    height: 600px; /* Korkeus 600px mobiilissa */
    width: 100%; /* 3D-näkymä vie 100% leveydestä mobiilissa */
    margin-bottom: 20px; /* Lisätään marginaali alapuolelle mobiilissa */
  }

  label {
    font-size: 14px; /* Pienennetään fonttikokoa mobiilissa */
  }

  input[type="number"],
  select {
    font-size: 14px; /* Pienennetään fonttikokoa mobiilissa */
    padding: 5px; /* Pienennetään paddingia mobiilissa */
  }

  .buttons-container {
    flex-direction: column; /* Asetetaan painikkeet päällekkäin mobiilissa */
    gap: 10px; /* Lisätään väliä painikkeiden väliin */
  }
}
