.privacy-policy {
    padding: 20px;
    background-color: #fff;
    color: #333;
    max-width: 800px;
    margin: 20px auto; /* Lisää hieman marginaalia ylä- ja alareunaan */
    font-family: 'Montserrat', sans-serif;
  }
  
  .privacy-policy h1 {
    color: var(--main-color);
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    color: #FF4500; /* Pääväri */
    font-size: 22px;
    margin-top: 20px;
  }
  
  .privacy-policy p {
    line-height: 1.6;
    font-size: 16px;
    margin-bottom: 15px;
  }
  