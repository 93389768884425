.contact-header {
  text-align: center;
  width: 100%;
}

.contact-form-container {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--container-background);
  border-radius: 10px;
  max-width: 600px;
  margin: 5px auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

form {
  display: flex;
  flex-direction: column;
}

form h2 {
  font-size: 24px;
  margin-bottom: 0px;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  max-width: 500px; /* Asetetaan saman levyisiksi kuin painike */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

form button {
  background: var(--main-color);
  color: white;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 85%;
  align-self: center;
}

form button:hover {
  background-color: var(--main-color);
  transform: scale(1.05);
}

.thank-you-message {
  text-align: center;
  color: var(--main-color);
}

.thank-you-message p {
  text-align: center;
  color: black;
}

.social-links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-links a svg {
  width: 50px;
  height: 50px;
  fill: black; /* Muutetaan ikonien väri mustaksi */
  transition: fill 0.3s ease, transform 0.3s ease;
}

.social-links a:hover svg {
  fill: #FF4500; /* Pääväri hover-tilassa */
  transform: scale(1.2); /* Zoom 1.2x hover-tilassa */
}