.services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: flex-start; /* Logot alkavat ylhäältä */
  padding: 50px;
  background-color: white; /* Pidetään tausta valkoisena */
  position: relative; /* Varmista, että z-index toimii oikein */
  z-index: 1; /* Asetetaan z-index, jotta services-elementti on footerin yläpuolella */
  font-family: 'Montserrat', sans-serif; /* Asetetaan fontti */
}

.service-item {
  text-align: center;
}

.service-logo {
  width: 150px;
  transition: transform 0.3s ease; /* Sulava suurennus */
}

.service-logo:hover {
  transform: scale(1.1);
}

/* Pyörähdysanimaatio suunnittelun automatisointi -logolle */
.service-item.automatisointi .service-logo:hover {
  transform: rotate(360deg) scale(1.1);
}

.service-item.automatisointi .service-logo {
  width: 200px
}

.service-item p {
  margin-top: 20px; /* Lisää marginaalia, jotta teksti on alempana logosta */
  font-weight: bold;
  color: black;
  font-size: 18px; /* Suurennetaan tekstejä */
  border-bottom: none; /* Poistetaan viivat tekstien alapuolelta */
  font-family: 'Montserrat', sans-serif; /* Varmistetaan fontti */
}

@media (max-width: 768px) {
  .services {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .service-item {
    margin-bottom: 20px;
  }

  .service-logo {
    width: 100px;
  }

  .service-item.automatisointi .service-logo {
    width: 100px
  }
  

  .service-item p {
    font-size: 16px;
  }
}
