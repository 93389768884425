/* App.css */
.App {
  background-color: white;
  color: black;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* Varmista, että z-index toimii oikein */
  z-index: 1; /* Asetetaan z-index, jotta App-elementti on footerin yläpuolella */
}

.contact-section {
  padding: 50px 20px;
  background-color: white; /* Pidetään tausta valkoisena */
  text-align: center;
  font-family: 'Montserrat', sans-serif; /* Varmistetaan fontti */
}

.contact-section h2 {
  color: #FF4500;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .contact-section {
    padding: 20px 10px;
  }

  .services {
    grid-template-columns: 1fr;
    padding: 20px 10px;
  }

  .service-item {
    margin-bottom: 20px;
  }

  .header, .footer {
    padding: 10px;
  }

  .footer-text, .nav-links li a, .nav-links li button {
    font-size: 14px;
  }

  .video-container {
    width: 100%;
    margin-top: -5em;
  }
}
