:root {
  --main-color: #FF4500;
}

.footer {
  position: sticky; /* Sticky footer */
  bottom: 0;
  text-align: center;
  color: black;
  padding: 20px;
  background-color: transparent; /* Läpinäkyvä tausta */
  z-index: 1; /* Asetetaan teksti kaiken muun yläpuolelle */
  transition: bottom 0.5s ease; /* Lisätään sulava siirtymä footerin sijainnille */
}

.footer::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px; /* Footerin korkeus */
  background-color: transparent; /* Läpinäkyvä tausta */
  z-index: -1; /* Vie tausta kaiken muun taakse */
}

.footer-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap; /* Estää tekstin rivittymisen */
  padding: 0 30px; /* Lisää marginaalia tekstin ja viivan väliin */
  transition: opacity 0.5s ease; /* Sulava häivytys */
  opacity: 0; /* Alustetaan näkymättömäksi */
}

.footer-line {
  height: 3px;
  background-color: var(--main-color);
  transition: width 0.5s ease; /* Sulava lyheneminen */
  width: 0; /* Alustetaan näkymättömäksi */
}

.footer-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Mobiiliyhteensopivuus */
@media (max-width: 768px) {
  .footer {
    padding: 10px; /* Pienennetään padding mobiililaitteille */
  }

  .footer-text {
    font-size: 14px; /* Pienennetään fonttikokoa mobiililaitteille */
    padding: 0 15px; /* Pienennetään marginaalia mobiililaitteille */
  }

  .footer-line {
    width: 30%; /* Pienennetään viivojen leveys mobiililaitteille */
  }

  body.cookies-accepted-0 .footer {
    bottom: 10vh; /* Siirretään footer 10vh ylemmäs */
  }
}
