.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  color: black;
  text-align: center;
  padding: 3px;
  z-index: 1000;
  font-size: 14px;
}

.cookie-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%; /* Leveämpi harmaa viiva */
  border-top: 1px solid #ccc; /* Harmaa viiva yläreunassa */
}

.cookie-banner p {
  margin: 0;
  display: inline;
}

.cookie-banner a {
  color: black; /* Teksti mustaksi */
  text-decoration: underline;
}

.cookie-banner button {
  margin-left: 20px;
  padding: 3px 8px;
  background-color: rgb(230, 230, 230); /* Käytä harmaata taustavärinä */
  color: black; /* Teksti mustaksi */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Sulava värimuutos */
}

.cookie-banner button:hover {
  background-color: #FF4500; /* Käytä pääväriä hover-tilassa */
  color: white; /* Teksti valkoiseksi hover-tilassa */
}

@media (max-width: 768px) {
  .cookie-banner {
    font-size: 12px;
    margin-left: -1.5vh;
  }

  .cookie-banner button {
    padding: 2px 6px;
  }
}
